import styled from 'styled-components';
import { darkBlue, grey } from 'constants/theme';

export const WorksheetDownload = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  padding: calc(1.55rem - 6px) 2rem;
  margin: 3rem auto;
  border-radius: 27px;
  border: 2px solid ${grey};
  align-self: center;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: ${darkBlue};
  cursor: pointer;
  transition: box-shadow 0.2s ease-out;
  line-height: 1;
  white-space: nowrap;

  .gatsby-image-wrapper {
    /* height: 20px; */
    width: 14px;
    margin-right: 1rem;
  }
`;
