import styled from 'styled-components'
import { offWhite } from 'constants/theme'
import MEDIA from 'helpers/mediaTemplates'
import { brNorm } from 'constants/theme'

export const Ad = styled.div`
  padding: 0rem 4rem;

  ${MEDIA.TABLET`
      padding: 0rem 1rem;
  `};

  ${MEDIA.PHONE`
      padding: 0rem 0rem;
  `};

  .content {
    max-width: 1400px;
    margin: 3rem 0rem;
    border-radius: ${brNorm};
    background-color: white;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    overflow: hidden;

    ${MEDIA.TABLET`
      flex-direction: column;
    `};
/* 
    ${MEDIA.PHONE`
    border-radius: 0;
    `}; */

    .left {
      display: flex;
      flex: 1;
      background: ${offWhite};
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      text-align: left;
      padding: 4rem;

      .title {
        max-width: 400px;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 2rem;
      }

      a {
        margin-top: 1rem;
      }

      .description {
        line-height: 1.2;
      }

      .read-more {
        margin-top: 2rem;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
`
