import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import axios from 'axios'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc'
import Cookies from 'js-cookie'
import GradientLink from 'components/gradient-link'
import Layout from 'components/layout'
import Box from 'components/box'
import WorksheetDownload from 'components/worksheet-download'
import Title from 'components/title'
import Banner from 'components/banner'
import Ad from 'components/ad'
import RedIndicator from 'components/red-indicator'
import { almostBlack, lightGrey, brNorm, rhythm } from 'constants/theme'
// import Gallery from 'components/gallery';
// import IOExample from 'components/io-example';
// import Modal from 'containers/modal';

const Button = ({ children, onClick }) => {
  return (
    <button className="add-to-calendar-button" onClick={onClick}>
      {children}
    </button>
  )
}

const Dropdown = ({ children }) => {
  return <div className="add-to-calendar-dropdown">{children}</div>
}

const ResiReplayInfo = styled.div`
  padding: ${rhythm};
  border-radius: 0 0 ${brNorm} ${brNorm};
  background-color: ${almostBlack};
  color: ${lightGrey};
  text-align: center;
  line-height: 1.4;

  a {
    color: white;
    text-decoration: underline;
  }

  strong {
    color: white;
  }
`

const StyledResiContainer = styled.div`
  width: 100%;
  margin: 4rem 0 6rem;
  padding: 0 4rem;

  .resi--container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    border-radius: ${brNorm} ${brNorm} 0 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`

const Index = ({ data }) => {
  //
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown)
  dayjs.extend(dayjsPluginUTC)
  const { events } = data.allEventsJson.nodes[0]

  // Component State
  const [theEvent, setTheEvent] = useState(events[0])
  const [eventTiming, setEventTiming] = useState(null)
  const [toggleOn, setToggleOn] = useState(0)

  async function init() {
    await checkFeatureToggles()
    websocketConnect()
    handleCookies()
    loadZendesk()
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    // check for event status every minute
    checkEventStatus(toggleOn)
    const interval = setInterval(() => {
      checkEventStatus(toggleOn)
    }, 60000)
    return () => clearInterval(interval)
  }, [toggleOn])

  const websocketConnect = () => {
    const heartbeat = ws => {
      ws.send('__beat__')
    }

    let ws
    const connect = () => {
      let interval

      ws = new WebSocket('wss://prod.ws.businessmadesimple.com/app/bms-mastery')

      // Setup our heartbeat to keep connection open
      ws.onopen = () => {
        console.log('Socket connected.')
        interval = setInterval(() => {
          heartbeat(ws)
        }, 30000)

        ws.send(
          JSON.stringify({
            event: 'pusher:subscribe',
            data: { auth: '', channel: 'mastery-api' }
          })
        )
      }

      // If heartbeat, ignore
      // Otherwise, update our store
      ws.onmessage = msg => {
        if (msg.data === '__alive__') return
        const { data, event } = JSON.parse(msg.data)
        const _data = data ? JSON.parse(data) : null

        if (event.includes('features.update')) {
          // if (event.includes('ToggleFeatureEvent')) {
          const toggle = _data.toggles.find(t => t.feature === 'force_live_stream')
          setToggleOn(toggle.on)
        }

        // if (event.includes('ToggleFeatureEvent')) {
        //   const toggle = data.toggles.find(t => t.feature === 'force_live_stream')
        //   setToggleOn(toggle.on)
        // }
      }

      // Clear our heartbeat if websocket closes unexpectedly
      ws.onclose = () => {
        ws = null
        clearInterval(interval)
        console.log('Socket connection has closed.')
      }

      ws.onerror = error => {
        console.log('Socket error: ', { error })
      }
    }

    const reconnect = async () => {
      try {
        await connect()
      } catch (error) {
        console.log('Error reconnecting to websocket: Error ', { error })
      }
    }

    // Initial socket connection
    try {
      connect()
    } catch (error) {
      console.log('Socket error connecting.')
    }

    // Reconnect to socket if we lose connection
    setInterval(() => {
      if (!ws) {
        reconnect()
      }
    }, 5000)
  }

  const handleCookies = () => {
    let ref
    const query = window.location.search.substring(1)
    const vars = query.split('&')

    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) == 'ref') {
        ref = decodeURIComponent(pair[1])
      }
    }

    if (ref && ref !== undefined && ref !== 'undefined') {
      Cookies.set('bmsu-livestream-ref', ref, {
        expires: 90,
        domain: 'businessmadesimple.com'
      })
    }
  }

  const loadZendesk = () => {
    let zdscript = document.createElement('script')
    zdscript.setAttribute('id', 'ze-snippet')
    zdscript.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.GATSBY_ZENDESK_KEY}`
    document.getElementsByTagName('body')[0].appendChild(zdscript)

    if (window) {
      var waitForZopim = setInterval(function() {
        if (window.$zopim === undefined || window.$zopim.livechat === undefined) {
          return
        }
        window.$zopim.livechat.badge.hide()
        clearInterval(waitForZopim)
      }, 100)
    }
  }

  const checkFeatureToggles = () => {
    return axios
      .get(process.env.GATSBY_MASTERY_API)
      .then(function(response) {
        // handle success
        if (response.data) {
          const toggle = response.data.find(f => f.feature === 'force_live_stream')
          setToggleOn(toggle.on)
        }
      })
      .catch(function(error) {
        // handle error
        // console.log(error);
      })
  }

  const checkEventStatus = FEATURE_TOGGLE => {
    // Get event to display
    const now = dayjs.utc()

    // if right now is within an hour of any event's start time
    const currentlyHappening = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc)
      const end = dayjs.utc(event.end_date_utc)
      const eventLength = start.diff(end, 'minute')
      const diff = start.diff(now, 'minute')
      return diff <= 15 && diff >= eventLength - 14
    })

    // if right now is within 3 hours of one of the event's end time
    const recentlyEndedEvents = events.filter(event => {
      const end = dayjs.utc(event.end_date_utc)
      const diff = end.diff(now, 'minute')
      return diff < -14 && diff > -180
    })

    // any events in the future
    const upcomingEvents = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc)
      const diff = start.diff(now, 'minute')
      return diff > 0
    })

    if (FEATURE_TOGGLE) {
      if (eventTiming !== 'currently') {
        setEventTiming('currently')
        setTheEvent(upcomingEvents[0])
      }
      return
    }

    if (upcomingEvents.length && !currentlyHappening.length && !recentlyEndedEvents.length) {
      if (eventTiming !== 'upcoming') {
        setEventTiming('upcoming')
        setTheEvent(upcomingEvents[0])
      }
    }

    if (currentlyHappening.length) {
      if (eventTiming !== 'currently') {
        setEventTiming('currently')
        setTheEvent(currentlyHappening[0])
      }
    }

    if (recentlyEndedEvents.length) {
      if (eventTiming !== 'over') {
        setEventTiming('over')
        setTheEvent(recentlyEndedEvents[0])
      }
    }
  }

  const StartDateInCentralTime = dayjs(theEvent.start_date_central)
  const EndDateInCentralTime = dayjs(theEvent.end_date_central)

  const AMorPM = date => {
    let amOrPm = null
    try {
      date ? (amOrPm = date.format('a') === 'am' ? 'a.m.' : 'p.m.') : null
    } catch (err) {
      //
    }
    return amOrPm
  }

  // Choosing the ad
  const { ads } = data.allAdsJson.nodes[0]

  // TODO get more ads and add logic
  const ad = ads[0]

  // Calendar Event
  const calendarEvent = event => {
    return {
      description: event.description,
      duration: 2,
      endDatetime: dayjs(theEvent.end_date_central).format('YYYYMMDDTHHmmss'),
      location: 'https://livestream.businessmadesimple.com',
      startDatetime: dayjs(theEvent.start_date_central).format('YYYYMMDDTHHmmss'),
      title: 'Business Made Simple Live Coaching',
      timezone: 'America/Chicago'
    }
  }

  return (
    <Layout>
      <Box>
        <Title as="h2" size="large">
          {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
        </Title>
        {eventTiming === 'currently' ? (
          <>
            {theEvent.workbook_download && (
              <WorksheetDownload href={theEvent.workbook_download}>
                <Img fluid={data.imageSharp ? data.imageSharp.fluid : {}} alt={'worksheet download'} />
                Worksheet Download
              </WorksheetDownload>
            )}
            <StyledResiContainer>
              <div className="resi--container">
                <iframe
                  title="Business Made Simple Live Coaching"
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                  src={`https://control.resi.io/webplayer/video.html?id=${theEvent.embed_code}&iframe=1&autoplay=1`}
                  style={{ width: '100%' }}
                ></iframe>
              </div>
              <ResiReplayInfo className="resi--replay-info">
                A replay of the event will be available exclusively for Business Made Simple Members on{' '}
                <strong>{StartDateInCentralTime.add('1', 'week').format('MMMM D, YYYY')}</strong>
                . <br />
                Want to watch the replay?{' '}
                <a
                  href={'https://app.businessmadesimple.com/sign-up/guide-coach-invitees'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to Become a Business Made Simple Member.
                </a>
              </ResiReplayInfo>
            </StyledResiContainer>
          </>
        ) : (
          <Banner>
            <BackgroundImage
              Tag="div"
              fluid={theEvent.banner_image.source.childImageSharp.fluid}
              // fluid={data.bannerJson.image.source.childImageSharp.fluid}
              className="banner-background"
              style={{
                backgroundPosition: 'center center',
                width: '100%',
                borderRadius: '3rem',
                overflow: 'hidden'
              }}
            >
              <div className="banner-content">
                <RedIndicator>
                  {eventTiming === 'upcoming' && (
                    <p className="no-margin">
                      Next <span className="hide-tablet">Livestream</span> Event
                    </p>
                  )}
                  {eventTiming === 'over' && 'This event is over'}
                </RedIndicator>
                <p className="banner-content__title">{data.bannerJson.title}</p>
                <h3 className="banner-content__topic">{theEvent.description}</h3>
                {eventTiming === 'upcoming' ? (
                  <>
                    <p className="banner-content__start-date">{StartDateInCentralTime.format('dddd, MMMM D, YYYY')}</p>
                    <p className="banner-content__start-time">
                      {StartDateInCentralTime.add(1, 'hour').format('h:mm')} {AMorPM(StartDateInCentralTime)} -{' '}
                      {EndDateInCentralTime.add(1, 'hour').format('h:mm')} {AMorPM(EndDateInCentralTime.add(1, 'hour'))}{' '}
                      ET
                      {'\n'}
                      {StartDateInCentralTime.format('h:mm')} {AMorPM(StartDateInCentralTime)} -{' '}
                      {EndDateInCentralTime.format('h:mm')} {AMorPM(EndDateInCentralTime)} CT
                      {'\n'}
                      {StartDateInCentralTime.subtract(2, 'hour').format('h:mm')} {AMorPM(StartDateInCentralTime)} -{' '}
                      {EndDateInCentralTime.subtract(2, 'hour').format('h:mm')}{' '}
                      {AMorPM(EndDateInCentralTime.subtract(2, 'hour'))} PT
                    </p>
                  </>
                ) : (
                  <div className="banner-content__over">
                    A replay of the event will be available exclusively for Business Made Simple Members on{' '}
                    <strong>{StartDateInCentralTime.add('1', 'week').format('MMMM D, YYYY')}</strong>
                    . <br />
                    Want to watch the replay? Click below to become a Business Made Simple member.
                  </div>
                )}
                {eventTiming === 'upcoming' ? (
                  <AddToCalendarDropdown
                    className="add-to-calendar"
                    buttonText="Add to my calendar"
                    event={calendarEvent(theEvent)}
                    items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                  />
                ) : null}
                {eventTiming === 'over' ? (
                  <GradientLink
                    href={'https://app.businessmadesimple.com/sign-up/guide-coach-invitees'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscribe To Watch The Replay
                  </GradientLink>
                ) : null}
              </div>
              {eventTiming === 'upcoming' ? (
                <ResiReplayInfo className="resi--replay-info">
                  A replay of the event will be available exclusively for Business Made Simple Members on{' '}
                  <strong>{StartDateInCentralTime.add(1, 'week').format('dddd, MMMM D, YYYY')}</strong>
                  . <br />
                  Want to watch the replay?{' '}
                  <a
                    href={'https://app.businessmadesimple.com/sign-up/guide-coach-invitees'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to Become a Business Made Simple Member.
                  </a>
                </ResiReplayInfo>
              ) : null}
            </BackgroundImage>
          </Banner>
        )}
        <Ad ad={ad} />
        {/* <IOExample /> */}

        {/* <Modal>
        <video
          src="https://i.imgur.com/gzFqNSW.mp4"
          playsInline
          loop
          autoPlay
          muted
        />
      </Modal> */}
      </Box>
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired
}

export default Index

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    bannerJson {
      title
      image {
        alt
        source {
          childImageSharp {
            fluid(maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allEventsJson {
      nodes {
        events {
          additional
          cta
          description
          embed_code
          start_date_utc
          end_date_utc
          start_date_central
          end_date_central
          id
          name
          workbook_download
          banner_image {
            alt
            source {
              childImageSharp {
                fluid(maxHeight: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        id
      }
    }
    allAdsJson {
      nodes {
        ads {
          copy
          cta
          cta_link
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    }
    imageSharp(original: { src: { regex: "/Worksheet/" } }) {
      gatsbyImageData
      fluid(maxWidth: 30) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
