import React from 'react';
import PropTypes from 'prop-types';
import { WorksheetDownload } from './worksheet-download.css';

const _WorksheetDownload = ({ children, href }) => (
  <WorksheetDownload href={href} target="_blank">
    {children}
  </WorksheetDownload>
);

_WorksheetDownload.propTypes = {
  children: PropTypes.node.isRequired,
};

export default _WorksheetDownload;
